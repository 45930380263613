import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";

import { RockItAuthenticationService } from "src/app/shell/rockit-authentication.service";

import { User } from "src/dto.generated/api";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { getUserLanguage } from "../utils/utils";

@Component({
  selector: "app-footer-component",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() languageCode: string;

  public loginOk = false;
  public loggedInUser: User;

  public isUserSignedIn: Observable<boolean>;
  private isUserSignedInSubscription: Subscription;

  constructor(
    private router: Router,
    socialAuthService: RockItAuthenticationService,

  ) {
    this.isUserSignedIn = socialAuthService.userId$.pipe(map(authState => authState != null));

    this.isUserSignedInSubscription = this.isUserSignedIn
      .subscribe(isUserSignedIn => {
        if (isUserSignedIn) {
          this.loginOk = true;
        } else {
          this.loginOk = false;
        }
      });

  }

  public async ngOnInit() {
    if (!this.languageCode) { this.languageCode = getUserLanguage(); }
    // console.log("TripsFooter:ngOnInit-languageCode", this.languageCode);
  }

  public async ngAfterViewInit() {
    // console.log("TripsFooter-ngAfterViewInit", this.languageCode);
  }

  public ngOnDestroy(): void {
    if (this.isUserSignedInSubscription) {
      this.isUserSignedInSubscription.unsubscribe();
    }
  }


  public onAboutClick() {
    this.router.navigate(["/info/about"]);
  }
  public onFreqAskedQuestClick() {
    this.router.navigate(["/info/faq"]);
  }
  public onPrivacyPolicyClick() {
    this.router.navigate(["/info/privacypolicy"]);
  }
  public onTermsOfUseClick() {
    this.router.navigate(["/info/termsofuse"]);
  }
  public onBugsReportClick() {
    this.router.navigate(["/info/bugs"]);
  }
  public onNewsClick() {
    this.router.navigate(["/info/news"]);
  }
  public async onContactClick() {
    this.router.navigate(["/info/impressum"]);
  }

}
