
import { Transfer, Event, Stay, Resto, Link, Info, Image, Duty, Notice, XDoc, RefTransfer, EnumTransportType, Trip, Container, Place } from "src/dto.generated/api";
import { ItemObject } from "../models/item-object";
import { getContainerInTrip, getContainerParentContainerForTrip, getContainerParentPlaceForTrip } from "./utils-container";
import { isDutyInTransfer } from "./utils-duty";
import { isEventInTransfer } from "./utils-event";
import { isImageInTransfer } from "./utils-image";
import { isInfoInTransfer } from "./utils-info";
import { isLinkInTransfer } from "./utils-link";
import { isNoticeInTransfer } from "./utils-notice";


export function createNewTransfer() {
  const newTransfer: Transfer = {} as Transfer;
  newTransfer.order = 1;
  newTransfer.dateActive = 1;
  newTransfer.from = new Date(Date.UTC(0, 0, 1));
  newTransfer.to = new Date(Date.UTC(0, 0, 1));
  newTransfer.transportType = EnumTransportType.Undef;
  newTransfer.events = new Array<Event>();
  newTransfer.duties = new Array<Duty>();
  newTransfer.links = new Array<Link>();
  newTransfer.infos = new Array<Info>();
  newTransfer.images = new Array<Image>();
  newTransfer.notices = new Array<Notice>();
  // newPlace.xDoc = new Array<XDoc>();
  return newTransfer;
}

export function cloneTransfer(transfer: Transfer) {
  const newTransfer: Transfer = {} as Transfer;
  newTransfer.id = transfer.id;
  newTransfer.name = transfer.name;
  newTransfer.transportType = transfer.transportType;
  newTransfer.order = transfer.order;
  newTransfer.from = transfer.from;
  newTransfer.to = transfer.to;
  newTransfer.dateActive = transfer.dateActive;
  newTransfer.fromPlaceName = transfer.fromPlaceName;
  newTransfer.fromRefPlaceId = transfer.fromRefPlaceId;
  newTransfer.toPlaceName = transfer.toPlaceName;
  newTransfer.toRefPlaceId = transfer.toRefPlaceId;
  newTransfer.routeParam = transfer.routeParam;
  newTransfer.containerId = transfer.containerId;
  newTransfer.tripId = transfer.tripId;
  newTransfer.duties = new Array<Duty>();
  newTransfer.images = new Array<Image>();
  newTransfer.infos = new Array<Info>();
  newTransfer.events = new Array<Event>();
  newTransfer.links = new Array<Link>();
  newTransfer.notices = new Array<Notice>();
  return newTransfer;
}

export function createNewTransferFromRefTransfer(refTransfer: RefTransfer) {
  console.log("Util-transfer:createNewTransferFromRefTransfer-refTransfer", refTransfer);
  const newTransfer = createNewTransfer();
  newTransfer.refTransferId = refTransfer.id;
  newTransfer.dateActive = 0;
  newTransfer.name = refTransfer.name;
  newTransfer.transportType = refTransfer.transportType;
  newTransfer.description = refTransfer.description;
  newTransfer.descUrl = refTransfer.descUrl;
  newTransfer.fromPlaceName = refTransfer.fromRefPlace.name;
  newTransfer.toPlaceName = refTransfer.toRefPlace.name;
  newTransfer.fromRefPlaceId = refTransfer.fromRefPlaceId;
  newTransfer.toRefPlaceId = refTransfer.toRefPlaceId;
  return newTransfer;
}

// export function getNewTransferId(transfers: Array<Transfer>) {
//   let id = 0;
//   for (const xTransfer of transfers) {
//     if (xTransfer.id < id) { id = xTransfer.id; }
//   }
//   id--;
//   return id;
// }

export function convertTransferDates(transfer: Transfer) {
  transfer.from = new Date(transfer.from);
  transfer.to = new Date(transfer.to);
}


export function isItemInTransfer(item: ItemObject, transfer: Transfer) {
  if (item.itemType === "Duty") {
    if (isDutyInTransfer(item.item, transfer)) { return true; }
  }
  if (item.itemType === "Event") {
    if (isEventInTransfer(item.item, transfer)) { return true; }
  }
  if (item.itemType === "Info") {
    if (isInfoInTransfer(item.item, transfer)) { return true; }
  }
  if (item.itemType === "Link") {
    if (isLinkInTransfer(item.item, transfer)) { return true; }
  }
  if (item.itemType === "Image") {
    if (isImageInTransfer(item.item, transfer)) { return true; }
  }
  if (item.itemType === "Notice") {
    if (isNoticeInTransfer(item.item, transfer)) { return true; }
  }
}
export function isTransferInContainer(transfer: Transfer, inContainer: Container) {

  // test all transfers directly in container
  for (const xTransfer of inContainer.transfers) {
    if (transfer.id === xTransfer.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of inContainer.containers) {
    if (isTransferInContainer(transfer, xContainer)) { return true; }
  }
  return false;
}


export function isTransferPrivate(transfer: Transfer, inTrip: Trip) {
  // console.log("Utils-Transfer:isTransferPrivate-transfer", transfer);
  // console.log("Utils-Transfer:isTransferPrivate-inTrip", inTrip);
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isTransferInContainer(transfer, xContainer)) { return true; }
  }
  return false;
}

export function getTransferInTrip(transferId: number, inTrip: Trip) {
  // console.log("EventFind:getPlaceInTrip-placeId", transferId);

  // test transfers of trip
  for (const xTransfer of inTrip.transfers) {
    if (xTransfer.id === transferId) { return xTransfer; }
  }
  // search in sub-containers
  for (const xContainer of inTrip.containers) {
    const transfer = getTransferInContainer(transferId, xContainer);
    if (transfer) { return transfer; }
  }
  // search in sub-places
  for (const xPlace of inTrip.places) {
    const place = getTransferInPlace(transferId, xPlace);
    if (place) { return place; }
  }
}
export function getTransferInContainer(transferId: number, inContainer: Container) {
  // console.log("EventFind:getTransferInContainer-transferId", transferId);

  // test transfers of container
  for (const xTransfer of inContainer.transfers) {
    if (xTransfer.id === transferId) { return xTransfer; }
  }
  // search in sub-containers
  for (const xContainer of inContainer.containers) {
    const transfer = getTransferInContainer(transferId, xContainer);
    if (transfer) { return transfer; }
  }
  // search in sub-places
  for (const xPlace of inContainer.places) {
    const transfer = getTransferInPlace(transferId, xPlace);
    if (transfer) { return transfer; }
  }
}
export function getTransferInPlace(transferId: number, inPlace: Place) {
  // console.log("EventFind:getTransferInPlace-transferId", transferId);

  // search in sub-containers
  for (const xContainer of inPlace.containers) {
    const transfer = getTransferInContainer(transferId, xContainer);
    if (transfer) { return transfer; }
  }
  // search in sub-places
  for (const xPlace of inPlace.places) {
    const transfer = getTransferInPlace(transferId, xPlace);
    if (transfer) { return transfer; }
  }
}

export function getMinLocalTransferIdForTrip(inTrip: Trip) {
  let id = 0;
  // test events of trip
  for (const xTransfer of inTrip.events) {
    if (xTransfer.id < id) { id = xTransfer.id; }
  }
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const eventId = getMinLocalTransferIdInContainer(xContainer);
    if (eventId < id) { id = eventId; }
  }
  return id;
}
export function getMinLocalTransferIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // test events of container
  for (const xTransfer of inContainer.events) {
    if (xTransfer.id < id) { id = xTransfer.id; }
  }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const eventId = getMinLocalTransferIdInContainer(xContainer);
    if (eventId < id) { id = eventId; }
  }
  return id;
}

export function getTransferParentContainerForTrip(transfer: Transfer, trip: Trip, onlyForRefRegion: boolean) {
  // console.log("EventFind:getTransferParentContainerForTrip-transfer", transfer);

  if (transfer.containerId) {
    const parentContainer = getContainerInTrip(transfer.containerId, trip);
    if (!onlyForRefRegion) { return parentContainer; }
    if (parentContainer.refRegionId) { return parentContainer; }
    const resContainer = getContainerParentContainerForTrip(parentContainer, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }
}
export function getTransferParentPlaceForTrip(transfer: Transfer, trip: Trip, onlyForRefPlace: boolean) {
  // console.log("EventFind:getPlaceParentPlaceForTrip-transfer", transfer);

  if (transfer.containerId) {
    const parentContainer = getContainerInTrip(transfer.containerId, trip);
    const resPlace = getContainerParentPlaceForTrip(parentContainer, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
}

