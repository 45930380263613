import { Container, Event, Info, Link, Place, Resto, Stay, Transfer, Trip } from "src/dto.generated/api";
import { convertDateTimeToTimeStringForDisplay } from "./utils";


export function createNewTrip() {
  const newTrip: Trip = {} as Trip;
  newTrip.from = new Date(Date.UTC(0, 0, 1));
  newTrip.to = new Date(Date.UTC(0, 0, 1));
  newTrip.useTimeMode = false;
  newTrip.viewMode = 1;
  newTrip.visible = true;
  newTrip.connectLocOnMap = true;
  newTrip.createdAt = new Date(Date.now());
  newTrip.containers = new Array<Container>();
  newTrip.events = new Array<Event>();
  newTrip.infos = new Array<Info>();
  newTrip.links = new Array<Link>();
  newTrip.places = new Array<Place>();
  newTrip.restos = new Array<Resto>();
  newTrip.stays = new Array<Stay>();
  newTrip.transfers = new Array<Transfer>();
  return newTrip;

}

export function cloneTrip(trip: Trip) {
  const newTrip: Trip = {} as Trip;
  newTrip.id = trip.id;
  newTrip.userId = trip.userId;
  newTrip.name = trip.name;
  newTrip.from = trip.from;
  newTrip.to = trip.to;
  newTrip.useTimeMode = trip.useTimeMode;
  newTrip.viewMode = trip.viewMode;
  newTrip.visible = trip.visible;
  newTrip.connectLocOnMap = trip.connectLocOnMap;
  newTrip.createdAt = trip.createdAt;
  newTrip.refRegionId = trip.refRegionId;
  newTrip.regionName = trip.regionName;
  newTrip.containers = new Array<Container>();
  newTrip.events = new Array<Event>();
  newTrip.infos = new Array<Info>();
  newTrip.links = new Array<Link>();
  newTrip.places = new Array<Place>();
  newTrip.restos = new Array<Resto>();
  newTrip.stays = new Array<Stay>();
  newTrip.transfers = new Array<Transfer>();
  return newTrip;
}

export function getNewTripId(trips: Array<Trip>) {
  let id = 0;
  for (const xTrip of trips) {
    if (xTrip.id < id) { id = xTrip.id; }
  }
  id--;
  return id;
}

export function convertTripDates(trip: Trip) {
  trip.from = new Date(trip.from);
  trip.to = new Date(trip.to);
}


export function getDefaultTripName(trip: Trip) {
  // console.log("getDefaultTripName-trip", trip);
  if (trip.name) { return trip.name; }
  let defaultTripName = "new trip";
  if (trip.regionName) { defaultTripName = trip.regionName; }
  if (trip.from < trip.to) {
    let countDays = ((trip.to.valueOf() - trip.from.valueOf()) / 1000.0 / 86400.0);
    countDays = Number.parseFloat(countDays.toFixed(1));
    if (countDays > 0) { defaultTripName = countDays + " days " + defaultTripName; }
    // console.log("getDefaultTripName-countDays", countDays);
  }
  return defaultTripName;
}

// find event in trip
export function findEventInTripByName(trip: Trip, name: string) {
  // console.log("findEventInTripByName-trip", trip);
  for (const xEvent of trip.events) {
    if (xEvent.name === name) { return xEvent; }
  }
  for (const xContainer of trip.containers) {
    const selectedEvent = findEventInContainerByName(xContainer, name);
    if (selectedEvent) { return selectedEvent; }
  }
  for (const xPlace of trip.places) {
    const selectedEvent = findEventInPlaceByName(xPlace, name);
    // console.log("findTripEventByName-selectedPlace", selectedEvent);
    if (selectedEvent) { return selectedEvent; }
  }
  for (const xTransfer of trip.transfers) {
    const selectedEvent = findEventInTransferByName(xTransfer, name);
    // console.log("findTripEventByName-selectedTransfer", selectedTEvent);
    if (selectedEvent) { return selectedEvent; }
  }
}
export function findEventInPlaceByName(place: Place, name: string) {
  for (const xEvent of place.events) {
    if (xEvent.name === name) { return xEvent; }
  }
  for (const xPlace of place.places) {
    const selectedEvent = findEventInPlaceByName(xPlace, name);
    if (selectedEvent) { return selectedEvent; }
  }
  for (const xContainer of place.containers) {
    const selectedEvent = findEventInContainerByName(xContainer, name);
    if (selectedEvent) { return selectedEvent; }
  }
}
export function findEventInContainerByName(container: Container, name: string) {
  for (const xEvent of container.events) {
    if (xEvent.name === name) { return xEvent; }
  }
  for (const xPlace of container.places) {
    const selectedEvent = findEventInPlaceByName(xPlace, name);
    if (selectedEvent) { return selectedEvent; }
  }
  for (const xContainer of container.containers) {
    const selectedEvent = findEventInContainerByName(xContainer, name);
    if (selectedEvent) { return selectedEvent; }
  }
}
export function findEventInTransferByName(transfer: Transfer, name: string) {
  for (const xEvent of transfer.events) {
    if (xEvent.name === name) { return xEvent; }
  }
}

// find place in trip
export function findPlaceInTripByName(trip: Trip, name: string) {
  // console.log("findTripPlaceByName-trip", trip);
  for (const xPlace of trip.places) {
    if (xPlace.name === name) { return xPlace; }
  }
  for (const xPlace of trip.places) {
    const selectedPlace = findPlaceInPlaceByName(xPlace, name);
    // console.log("findTripPlaceByName-selectedPlace", selectedPlace);
    if (selectedPlace) { return selectedPlace; }
  }
  for (const xContainer of trip.containers) {
    const selectedPlace = findPlaceInContainerByName(xContainer, name);
    if (selectedPlace) { return selectedPlace; }
  }
}
export function findPlaceInPlaceByName(place: Place, name: string) {
  for (const xPlace of place.places) {
    if (xPlace.name === name) { return xPlace; }
  }
  for (const xPlace of place.places) {
    const selectedPlace = findPlaceInPlaceByName(xPlace, name);
    if (selectedPlace) { return selectedPlace; }
  }
  for (const xContainer of place.containers) {
    const selectedPlace = findPlaceInContainerByName(xContainer, name);
    if (selectedPlace) { return selectedPlace; }
  }
}
export function findPlaceInContainerByName(container: Container, name: string) {
  for (const xPlace of container.places) {
    if (xPlace.name === name) { return xPlace; }
    const selectedPlace = findPlaceInPlaceByName(xPlace, name);
    if (selectedPlace) { return selectedPlace; }
  }
  for (const xContainer of container.containers) {
    const selectedPlace = findPlaceInContainerByName(xContainer, name);
    if (selectedPlace !== null) { return selectedPlace; }
  }
}

// find resto in trip
export function findRestoInTripByName(trip: Trip, name: string) {
  // console.log("findRestoInTripByName-trip", trip);
  for (const xResto of trip.restos) {
    if (xResto.name === name) { return xResto; }
  }
  for (const xPlace of trip.places) {
    const selectedPlace = findRestoInPlaceByName(xPlace, name);
    if (selectedPlace) { return selectedPlace; }
  }
  for (const xContainer of trip.containers) {
    const selectedPlace = findRestoInContainerByName(xContainer, name);
    if (selectedPlace) { return selectedPlace; }
  }
}
export function findRestoInPlaceByName(place: Place, name: string) {
  for (const xResto of place.restos) {
    if (xResto.name === name) { return xResto; }
  }
  for (const xPlace of place.places) {
    const selectedEvent = findRestoInPlaceByName(xPlace, name);
    if (selectedEvent) { return selectedEvent; }
  }
  for (const xContainer of place.containers) {
    const selectedEvent = findRestoInContainerByName(xContainer, name);
    if (selectedEvent) { return selectedEvent; }
  }
}
export function findRestoInContainerByName(container: Container, name: string) {
  for (const xResto of container.restos) {
    if (xResto.name === name) { return xResto; }
  }
  for (const xPlace of container.places) {
    const selectedEvent = findRestoInPlaceByName(xPlace, name);
    if (selectedEvent) { return selectedEvent; }
  }
  for (const xContainer of container.containers) {
    const selectedEvent = findRestoInContainerByName(xContainer, name);
    if (selectedEvent) { return selectedEvent; }
  }
}

// find stay in trip
export function findStayInTripByName(trip: Trip, name: string) {
  // console.log("findStayInTripByName-trip", trip);
  // for (const xStay of trip.stays) {
  //   if (xStay.name === name) { return xStay; }
  // }
  for (const xPlace of trip.places) {
    const selectedPlace = findStayInPlaceByName(xPlace, name);
    if (selectedPlace) { return selectedPlace; }
  }
  for (const xContainer of trip.containers) {
    const selectedPlace = findStayInContainerByName(xContainer, name);
    if (selectedPlace) { return selectedPlace; }
  }
}
export function findStayInPlaceByName(place: Place, name: string) {
  for (const xStay of place.stays) {
    if (xStay.name === name) { return xStay; }
  }
  for (const xPlace of place.places) {
    const selectedEvent = findStayInPlaceByName(xPlace, name);
    if (selectedEvent) { return selectedEvent; }
  }
  for (const xContainer of place.containers) {
    const selectedEvent = findStayInContainerByName(xContainer, name);
    if (selectedEvent) { return selectedEvent; }
  }
}
export function findStayInContainerByName(container: Container, name: string) {
  for (const xStay of container.stays) {
    if (xStay.name === name) { return xStay; }
  }
  for (const xPlace of container.places) {
    const selectedEvent = findStayInPlaceByName(xPlace, name);
    if (selectedEvent) { return selectedEvent; }
  }
  for (const xContainer of container.containers) {
    const selectedEvent = findStayInContainerByName(xContainer, name);
    if (selectedEvent) { return selectedEvent; }
  }
}

// find transfer in trip by id
export function findTransferInTripById(trip: Trip, id: number) {
  // console.log("findTransferInTripById-trip", trip);
  for (const xTransfer of trip.transfers) {
    if (xTransfer.id === id) { return xTransfer; }
  }
  for (const xContainer of trip.containers) {
    const selectedTransfer = findTransferInContainerById(xContainer, id);
    if (selectedTransfer) { return selectedTransfer; }
  }
}
export function findTransferInContainerById(container: Container, id: number) {
  for (const xTransfer of container.transfers) {
    if (xTransfer.id === id) { return xTransfer; }
  }
  for (const xContainer of container.containers) {
    const selectedTransfer = findTransferInContainerById(xContainer, id);
    if (selectedTransfer) { return selectedTransfer; }
  }
}
