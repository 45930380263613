
import { Place, Event, Stay, Resto, Link, Info, Image, Duty, Notice, XDoc, EnumPlaceType, Container, Trip } from "src/dto.generated/api";
import { RefPlace } from "src/dto.generated/api";
import { ItemObject } from "../models/item-object";
import { getContainerInTrip, getContainerParentContainerForTrip, getContainerParentPlaceForTrip, isContainerInPlace } from "./utils-container";
import { isDutyInPlace } from "./utils-duty";
import { isEventInPlace } from "./utils-event";
import { isImageInPlace } from "./utils-image";
import { isInfoInPlace } from "./utils-info";
import { isLinkInPlace } from "./utils-link";
import { isNoticeInPlace } from "./utils-notice";
import { isRestoInPlace } from "./utils-resto";
import { isStayInPlace } from "./utils-stay";


export function createNewPlace() {
  const newPlace: Place = {} as Place;
  newPlace.order = 1;
  newPlace.from = new Date(Date.UTC(0, 0, 1));
  newPlace.to = new Date(Date.UTC(0, 0, 1));
  newPlace.dateActive = 1;
  newPlace.containers = new Array<Container>();
  newPlace.places = new Array<Place>();
  newPlace.events = new Array<Event>();
  newPlace.stays = new Array<Stay>();
  newPlace.restos = new Array<Resto>();
  newPlace.links = new Array<Link>();
  newPlace.infos = new Array<Info>();
  newPlace.images = new Array<Image>();
  newPlace.duties = new Array<Duty>();
  newPlace.notices = new Array<Notice>();
  // newPlace.xDoc = new Array<XDoc>();
  return newPlace;
}
export function clonePlace(place: Place) {
  const newPlace: Place = {} as Place;
  newPlace.id = place.id;
  newPlace.name = place.name;
  newPlace.order = place.order;
  newPlace.from = place.from;
  newPlace.to = place.to;
  newPlace.dateActive = place.dateActive;
  newPlace.rating = place.rating;
  newPlace.zoom = place.zoom;
  newPlace.coordLon = place.coordLon;
  newPlace.coordLat = place.coordLat;
  newPlace.containerId = place.containerId;
  newPlace.placeId = place.placeId;
  newPlace.tripId = place.tripId;
  newPlace.containers = new Array<Container>();
  newPlace.places = new Array<Place>();
  newPlace.events = new Array<Event>();
  newPlace.stays = new Array<Stay>();
  newPlace.restos = new Array<Resto>();
  newPlace.links = new Array<Link>();
  newPlace.infos = new Array<Info>();
  newPlace.images = new Array<Image>();
  newPlace.duties = new Array<Duty>();
  newPlace.notices = new Array<Notice>();
  return newPlace;
}

export function createNewPlaceFromRefPlace(refPlace: RefPlace) {
  const newPlace = createNewPlace();
  newPlace.refPlaceId = refPlace.id;
  newPlace.name = refPlace.name;
  newPlace.dateActive = 0;
  newPlace.rating = refPlace.rating;
  newPlace.description = refPlace.description;
  newPlace.descUrl = refPlace.descUrl;
  newPlace.coordLon = refPlace.coordLon;
  newPlace.coordLat = refPlace.coordLat;
  newPlace.zoom = refPlace.zoom;
  return newPlace;
}

export function orderPlacesByOrder(places: Place[]) {
  // console.log("utils-place:orderPlaces");
  places.sort((a, b) => {
    if (a.order < b.order) { return -1; }
    if (a.order > b.order) { return 1; }
    return 0;
  });
}

// export function getNewPlaceId(places: Array<Place>) {
//   let id = 0;
//   for (const xPlace of places) {
//     if (xPlace.id < id) { id = xPlace.id; }
//   }
//   id--;
//   return id;
// }

export function convertPlaceDates(place: Place) {
  place.from = new Date(place.from);
  place.to = new Date(place.to);
}


export function isItemInPlace(item: ItemObject, place: Place) {
  if (item.itemType === "Container") {
    if (isContainerInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Event") {
    if (isEventInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Duty") {
    if (isDutyInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Image") {
    if (isImageInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Info") {
    if (isInfoInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Link") {
    if (isLinkInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Notice") {
    if (isNoticeInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Place") {
    if (isPlaceInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Resto") {
    if (isRestoInPlace(item.item, place)) { return true; }
  }
  if (item.itemType === "Stay") {
    if (isStayInPlace(item.item, place)) { return true; }
  }
}
export function isPlaceInContainer(place: Place, inContainer: Container) {

  // test all places directly in container
  for (const xPlace of inContainer.places) {
    if (place.id === xPlace.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of inContainer.containers) {
    if (isPlaceInContainer(place, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of inContainer.places) {
    if (isPlaceInPlace(place, xPlace)) { return true; }
  }
  return false;
}

export function isPlaceInPlace(place: Place, inPlace: Place) {

  // test all places directly in inPlace
  for (const xPlace of inPlace.places) {
    if (place.id === xPlace.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of inPlace.containers) {
    if (isPlaceInContainer(inPlace, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of inPlace.places) {
    if (isPlaceInPlace(inPlace, xPlace)) { return true; }
  }
  return false;
}

export function isPlacePrivate(place: Place, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isPlaceInContainer(place, xContainer)) { return true; }
  }
  return false;
}

export function getPlaceInTrip(placeId: number, inTrip: Trip) {
  // console.log("EventFind:getPlaceInTrip-placeId", placeId);

  // test places of trip
  for (const xPlace of inTrip.places) {
    if (xPlace.id === placeId) { return xPlace; }
  }
  // search in containers
  for (const xContainer of inTrip.containers) {
    const place = getPlaceInContainer(placeId, xContainer);
    if (place) { return place; }
  }
  // search in places
  for (const xPlace of inTrip.places) {
    const place = getPlaceInPlace(placeId, xPlace);
    if (place) { return place; }
  }
}
export function getPlaceInContainer(placeId: number, inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);

  // test places of container
  for (const xPlace of inContainer.places) {
    if (xPlace.id === placeId) { return xPlace; }
  }
  // search in sub-containers
  for (const xContainer of inContainer.containers) {
    const place = getPlaceInContainer(placeId, xContainer);
    if (place) { return place; }
  }
  // search in sub-places
  for (const xPlace of inContainer.places) {
    const place = getPlaceInPlace(placeId, xPlace);
    if (place) { return place; }
  }
}
export function getPlaceInPlace(placeId: number, inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);

  // test places of place
  for (const xPlace of inPlace.places) {
    if (xPlace.id === placeId) { return xPlace; }
  }
  // search in sub-containers
  for (const xContainer of inPlace.containers) {
    const place = getPlaceInContainer(placeId, xContainer);
    if (place) { return place; }
  }
  // search in sub-places
  for (const xPlace of inPlace.places) {
    const place = getPlaceInPlace(placeId, xPlace);
    if (place) { return place; }
  }
}

export function getMinLocalPlaceIdForTrip(inTrip: Trip) {
  let id = 0;
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const placeId = getMinLocalPlaceIdInContainer(xContainer);
    if (placeId < id) { id = placeId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const placeId = getMinLocalPlaceIdInPlace(xPlace);
    if (placeId < id) { id = placeId; }
  }
  return id;
}
export function getMinLocalPlaceIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const placeId = getMinLocalPlaceIdInContainer(xContainer);
    if (placeId < id) { id = placeId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const placeId = getMinLocalPlaceIdInPlace(xPlace);
    if (placeId < id) { id = placeId; }
  }
  return id;
}
export function getMinLocalPlaceIdInPlace(inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test place
  if (inPlace.id < id) { id = inPlace.id; }
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const placeId = getMinLocalPlaceIdInContainer(xContainer);
    if (placeId < id) { id = placeId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const placeId = getMinLocalPlaceIdInPlace(xPlace);
    if (placeId < id) { id = placeId; }
  }
  return id;
}

export function getPlaceParentContainerForTrip(place: Place, trip: Trip, onlyForRefRegion: boolean) {
  // console.log("utils-place:getPlaceParentContainerForTrip-place", place);

  if (place.containerId) {
    const parentContainer = getContainerInTrip(place.containerId, trip);
    if (!onlyForRefRegion) { return parentContainer; }
    if (parentContainer.refRegionId) { return parentContainer; }
    const resContainer = getContainerParentContainerForTrip(parentContainer, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }
  if (place.placeId) {
    const parentPlace = getPlaceInTrip(place.placeId, trip);
    const resContainer = getPlaceParentContainerForTrip(parentPlace, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }
}
export function getPlaceParentPlaceForTrip(place: Place, trip: Trip, onlyForRefPlace: boolean) {
  console.log("utils-place:getPlaceParentPlaceForTrip-place", place);

  if (place.containerId) {
    const parentContainer = getContainerInTrip(place.containerId, trip);
    const resPlace = getContainerParentPlaceForTrip(parentContainer, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
  if (place.placeId) {
    const parentPlace = getPlaceInTrip(place.containerId, trip);
    if (!onlyForRefPlace) { return parentPlace; }
    if (parentPlace.refRegionId) { return parentPlace; }
    const resPlace = getPlaceParentPlaceForTrip(parentPlace, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
}

