import { Breakpoints } from "@angular/cdk/layout";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { EnumActionType, EnumAppType, UsageLog } from "src/dto.generated/api";
import { GlobalService } from "../services/global.service";

export function getNavigatorLanguage() {
  // user-language
  const navigatorLanguage = navigator.language;
  const userLanguage = this.navigatorLanguage.substring(0, 2).toLowerCase();
  return userLanguage;
}
export function getUserLanguage() {

  // const availableLanguages = ["en", "de", "es"];
  const availableLanguages = ["en", "de"];
  const defaultLanguage = "en";

  let browserCultures: string[] = [];
  if (navigator.languages) {
    browserCultures = [...navigator.languages];
  } else {
    browserCultures = [navigator.language];
  }

  let browserLanguages = browserCultures;
  // console.log("utils:getNavigatorLanguage-browserLanguages", browserLanguages);
  browserLanguages = browserCultures.map(c1 => c1.split("-").find(c2 => true));
  const requestedLanguageCode = browserLanguages.find(bl => availableLanguages.find(al => al === bl) !== undefined);

  const languageCodeToUse = requestedLanguageCode ? requestedLanguageCode : defaultLanguage;
  return languageCodeToUse;
}

export function calcDateDiffInDays(date1: Date, date2: Date) {

  const countDays = ((date1.valueOf() - date2.valueOf()) / 1000.0 / 86400.0);
  return countDays;
}

export function addHoursToDate(date: Date, hours: number) {

  const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + hours, date.getMinutes());
  return newDate;
}

export function convertDateForDisplay(localTime: Date) {
  // console.log("ConvertDateForDisplay-localTime", localTime);
  if (!localTime) { return undefined; }
  const utcTime = new Date(localTime.getUTCFullYear(), localTime.getUTCMonth(), localTime.getUTCDate());
  return utcTime;
}

export function convertDateToUTCDate(localTime: Date) {
  if (!localTime) { return undefined; }
  const utcTime = new Date(Date.UTC(localTime.getFullYear(), localTime.getMonth(), localTime.getDate()));
  return utcTime;
}

export function convertDateTimeToTimeStringForDisplay(date: Date) {
  // console.log("ConvertDateToTimeString", date)

  date = new Date(date);
  const hour: number = date.getUTCHours();
  const min: number = date.getUTCMinutes();
  // console.log("ConvertDateToTimeString-hour", hour);
  // console.log("ConvertDateToTimeString-min", min);
  let timeString = "";
  if (hour < 10) { timeString = "0" + hour.toString(); }
  if (hour >= 10) { timeString = hour.toString(); }
  // console.log("ConvertDateToTimeString-timeString1", timeString);
  timeString += ":";
  if (min < 10) { timeString += "0" + min.toString(); }
  if (min >= 10) { timeString += min.toString(); }
  // console.log("ConvertDateToTimeString-timeString2", timeString);
  return timeString;
}

export function setTimeOfDateByTimeString(utcDate: Date, timeString: string) {
  // console.log("addTimeOfDate", date);
  // console.log("addTimeOfDate", timeString);

  const hourString = timeString.slice(0, 2);
  const hour: number = parseInt(hourString, 10);
  const minString = timeString.slice(3, 5);
  const min: number = parseInt(minString, 10);
  utcDate = new Date(Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), hour, min));
  // console.log("setTimeOfDateByTimeString-date", utcDate);

  return utcDate;
}

export function setShortUrlText(url: string, nchar: number) {

  if (!nchar) { nchar = 40; }
  if (url === null || url === undefined) { return null; }
  if (url.length > nchar) { return url.substring(0, nchar) + "..."; }
  return url;
}
export function setLongUrlText(url: string) {

  if (url === null || url === undefined) { return null; }
  if (url.length > 80) { return url.substring(0, 80) + "..."; }
  return url;
}

export function setShortInfoText(text: string) {

  if (text === null || text === undefined) { return null; }
  if (text.length > 80) { return text.substring(0, 80) + "..."; }
  return text;
}

export function setPriceCategoryText(priceCategory: number) {

  // priceCategoryText
  let priceCategoryText = "?";
  if (priceCategory === 1) { priceCategoryText = "$"; }
  if (priceCategory === 2) { priceCategoryText = "$$"; }
  if (priceCategory === 3) { priceCategoryText = "$$$"; }
  if (priceCategory === 4) { priceCategoryText = "$$$$"; }
  return priceCategoryText;
}

export function isWhitespace(input) {

  // if (typeof input === 'undefined' || input == null) return true;

  return input.replace(/\s/g, "").length < 1;
}

export function setBestTravelTimeText(bestVisitCode: string, languageCode: string) {

  let text = "?";
  if (!bestVisitCode) { return text; }

  if (languageCode === "?") { languageCode = "en"; }
  text = "Best travel time: ";

  // if (languageCode === "en") {
  //   text = "Best travel time: ";
  // }
  // if (languageCode === "de") {
  //   text = "Beste Reisezeit: ";
  // }
  // if (languageCode === "es") {
  //   text = "Mejor tiempo para visitar: ";
  // }
  const parts = bestVisitCode.split(";");
  if (!parts.length) { parts.push(bestVisitCode); }

  for (const part of parts) {
    const items = part.split("-");
    if (items.length === 2) {
      const fromMonth = Number(items[0]);
      const toMonth = Number(items[1]);
      text = text + " " + getMonthName(fromMonth, languageCode) + " - " + getMonthName(toMonth, languageCode);
    }
    if (parts.indexOf(part) !== parts.length - 1) { text = text + ";"; }
  }
  return text;
}

export function getMonthName(month: number, languageCode: string) {

  let name = "Jan";
  if (month === 2) { name = "Feb"; }
  if (month === 3) { name = "Mar"; }
  if (month === 4) { name = "Apr"; }
  if (month === 5) { name = "May"; }
  if (month === 6) { name = "Jun"; }
  if (month === 7) { name = "Jul"; }
  if (month === 8) { name = "Aug"; }
  if (month === 9) { name = "Sep"; }
  if (month === 10) { name = "Okt"; }
  if (month === 11) { name = "Nov"; }
  if (month === 12) { name = "Dez"; }
  return name;
}

export interface IIP {
  ip: string;
}
export async function getIP(http: HttpClient) {
  let result = null;
  result = await http.get<IIP>("https://api.ipify.org/?format=json")
    .pipe(
      tap({
        error: (error) => {
          console.log("Error during getIP:", error);
        }
      })
    ).toPromise();

  // console.log("utils:getIP-result", result);
  return result;
}

// tslint:disable-next-line:max-line-length
export async function addUsageLog(globalService: GlobalService, userIP: string, userLanguage: string, appType: EnumAppType, actionType: EnumActionType, actionInfo: string, at: Date) {

  const usageLog = {} as UsageLog;
  usageLog.userIP = userIP;
  usageLog.userLanguage = userLanguage;
  usageLog.appType = appType;
  usageLog.actionType = actionType;
  usageLog.actionInfo = actionInfo;
  usageLog.at = at;
  return await globalService.addUsageLog(usageLog);
}

export function getDeviceTypeName(query: any) {
  // Create a map to display breakpoint names for demonstration purposes.
  const displaySizeNameMap = new Map([
    [Breakpoints.HandsetPortrait, "H-P"],
    [Breakpoints.TabletPortrait, "T-P"],
    [Breakpoints.WebPortrait, "W-P"],
    [Breakpoints.HandsetLandscape, "H-L"],
    [Breakpoints.TabletLandscape, "T-L"],
    [Breakpoints.WebLandscape, "W-L"],
  ]);
  const deviceType = displaySizeNameMap.get(query);
  // console.log("utils:deviceType", deviceType);
  return deviceType;
}

