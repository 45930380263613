<!-- Info-Menu -->
<div class="container-fluid" style="background:linen;margin-left:0.0em;margin-top:0.5em;margin-bottom:0.5em;">
  <span style="margin-left:1.0em; cursor:pointer" (click)="onAboutClick()"> About Trip4You </span>
  <span style="margin-left:1.0em; cursor:pointer" (click)="onFreqAskedQuestClick()"> Frequently asked questions </span>
  <span style="margin-left:1.0em; cursor:pointer" (click)="onPrivacyPolicyClick()"> Privacy policy </span>
  <span style="margin-left:1.0em; cursor:pointer" (click)="onTermsOfUseClick()"> Terms of use </span>
  <span style="margin-left:1.0em; cursor:pointer" (click)="onBugsReportClick()"> Report bugs & problems </span>
  <span style="margin-left:1.0em; cursor:pointer" (click)="onNewsClick()"> News </span>
  <span style="margin-left:1.0em; cursor:pointer" (click)="onContactClick()"> Impressum </span>
</div>
<hr>