import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { RockItAuthenticationService } from "../rockit-authentication.service";
import { RockitSocialLoginPopupComponent } from "../rockit-social-login-popup/rockit-social-login-popup.component";
import { RockitSocialLogoutPopupComponent } from "../rockit-social-logout-popup/rockit-social-logout-popup.component";

@Component({
    selector: "rockit-social-login",
    templateUrl: "./rockit-social-login.component.html",
    styleUrls: ["./rockit-social-login.component.scss"]
})
export class RockItSocialLoginComponent {
    public loggedIn$: Observable<boolean>;
    public userDetails$: Observable<string>;
    public identityProvider$: Observable<string>;

    constructor(public dialog: MatDialog, private authenticationService: RockItAuthenticationService) {
        this.loggedIn$ = this.authenticationService.userId$.pipe(map(id => id != null));
        this.userDetails$ = this.authenticationService.userName$;
        this.identityProvider$ = this.authenticationService.identityProvider$;
    }

    async showLoginLogoutDialog(): Promise<void> {
        if (await this.loggedIn$.pipe(take(1)).toPromise()) {
            const dialogRef = this.dialog.open(RockitSocialLogoutPopupComponent, {
                closeOnNavigation: true,
            });
            // TODO:
            //     dialogRef.afterClosed().subscribe(result => {
            // });
        } else {
            const dialogRef = this.dialog.open(RockitSocialLoginPopupComponent, {
                closeOnNavigation: true,
                // disableClose: true
            });
            // TODO:
            //     dialogRef.afterClosed().subscribe(result => {
            // });
        }
    }
}
