import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { fromEvent, Observable, Subscription } from "rxjs";
import { delay, map } from "rxjs/operators";

import { User } from "src/dto.generated/api";
import { environment } from "src/environments/environment";
import { TripService } from "./services/trip.service";
import { RockItAuthenticationService } from "./shell/rockit-authentication.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {

  public releaseDate: string = environment.releaseDate;
  public isOnline: boolean;

  // logedin-user
  public isUserSignedIn: Observable<boolean>;
  private isUserSignedInSubscription: Subscription;
  public loggedInUser: User;
  public isUserAdmin: boolean;

  private offlineEvent: Observable<Event>;
  private offLineSubscription: Subscription;
  private onlineEvent: Observable<Event>;
  private onLineSubscription: Subscription;


  constructor(
    private router: Router,
    private socialAuthService: RockItAuthenticationService,
    private tripService: TripService,
  ) {
    this.isUserSignedIn = socialAuthService.userId$.pipe(map(authState => authState != null));
    this.isUserSignedInSubscription = this.isUserSignedIn.subscribe(isUserSignedIn => {
      if (isUserSignedIn) { this.getLoggedInUser(); }
    });
    this.isUserSignedInSubscription = this.isUserSignedIn.pipe(delay(50)).subscribe(isUserSignedIn => { });
  }


  public ngOnInit() {
    this.isOnline = navigator.onLine;
    console.log("App:ngOnInit-isOnline", this.isOnline);

    this.offlineEvent = fromEvent(window, "offline");
    this.offLineSubscription = this.offlineEvent.subscribe(e => {
      console.log("App:offlineEvent");
      this.isOnline = false;
    });

    this.onlineEvent = fromEvent(window, "online");
    this.onLineSubscription = this.onlineEvent.subscribe(e => {
      console.log("App:onlineEvent");
      this.isOnline = true;
    });
  }
  public ngOnDestroy() {
    this.offLineSubscription.unsubscribe();
    this.onLineSubscription.unsubscribe();
    if (this.isUserSignedInSubscription) {
      this.isUserSignedInSubscription.unsubscribe();
    }
  }

  private async getLoggedInUser() {
    this.loggedInUser = await this.tripService.getLoggedInUser();
    // console.log("AdminRefItems:LoadData-loggedInUser", this.loggedInUser);
    if (this.loggedInUser.adminPriv > 0) { this.isUserAdmin = true; }
  }

  public onGoToTravelInfoClick() {
    this.router.navigate(["/explore"]);
  }
  public onGoToTravelPlannerClick() {
    this.router.navigate(["/planner"]);
  }
  public onGoToMapsClick() {
    window.location.href = "https://maps.trip4you.net";
  }
  public onShowAdminClick() {
    this.router.navigate(["/admin"]);
  }

}
