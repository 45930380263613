<div>

  <mat-toolbar class="app-header">

    <button mat-icon-button [matMenuTriggerFor]="menu" style="margin-left:0.5rem;">
      <img style="height:30px;" src="./assets/icons/menu.svg" title="menu">
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onGoToTravelInfoClick()">
        <img width="20px" src="./assets/icons/information-variant.svg"> Travel information</button>
      <button mat-menu-item (click)="onGoToTravelPlannerClick()">
        <img width="20px" src="./assets/icons/todo-yellowgreen.svg"> Travel planner</button>
      <button mat-menu-item (click)="onGoToMapsClick()">
        <img width="20px" src="./assets/icons/directions.svg"> Route planning</button>
      <button *ngIf="isUserAdmin" mat-menu-item (click)="onShowAdminClick()">
        <img width="20px" src="./assets/icons/region-orange.svg">Admin</button>
    </mat-menu>

    <img width="32px" height="32px" src="./assets/icons_logo/icon-192x192.png" alt="trip4you-icon"
      style="vertical-align: center;">
    <span class="app-name" style="margin-left:0.5em;vertical-align:top;">Trip4You</span>

    <div
      style="flex-grow: 1; flex-shrink: 1; flex-basis: auto; font-size:large; text-align: center; vertical-align:center;">
      <!-- <img src="./assets/icons/web-cancel.svg" [class.hidden]="!isOnline" title="you are offline"> -->
      <span *ngIf="!isOnline" style="margin-left:0.5rem;">offline</span>
    </div>

    <div *ngIf="isOnline"
      style="flex-grow: 0; flex-shrink: 1; flex-basis: content; text-overflow: ellipsis; overflow: hidden; padding-right: 0.5em;"
      class="topbar-user-container">
      <rockit-social-login></rockit-social-login>
    </div>

    <!-- <div style="display: flex; width:100%; max-width: 100%; flex-direction: row;">
    <div style="flex-grow: 0; flex-shrink: 1; flex-basis: auto;">
      <img width="30px" height="30px" src="./assets/icons_logo/icon-192x192.png" style="margin-bottom:8px;"
        alt="trip4you-icon">
      <span class="app-name" style="margin-left:0.5em;vertical-align:top;">Trip4You</span>
    </div>

    <div
      style="flex-grow: 1; flex-shrink: 0; flex-basis: content; font-size:large; text-align: center; vertical-align:center;">
      <span *ngIf="!isOnline" style="margin-left:0.5rem;">offline</span>
    </div>

    <div *ngIf="isOnline"
      style="flex-grow: 0; flex-shrink: 1; flex-basis: content; text-overflow: ellipsis; overflow: hidden; padding-right: 0.5em;"
      class="topbar-user-container">
      <rockit-social-login></rockit-social-login>
    </div>
  </div> -->

  </mat-toolbar>

</div>

<router-outlet></router-outlet>