import { NgModule, } from "@angular/core";
import { RouterModule, } from "@angular/router";
import { FormsModule, } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbAccordionModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FooterComponent } from "./footer.component";

@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,

    NgbAccordionModule,

  ],
  providers: [
  ],
  exports: [
    FooterComponent,
  ]
})
export class FooterModule {

}
