
import { Container, EnumNoticeType, Event, Notice, Place, Resto, Stay, Transfer, Trip } from "src/dto.generated/api";


export function createNewNotice() {
  const newNotice: Notice = {} as Notice;
  newNotice.order = 1;
  return newNotice;
}

export function cloneNotice(notice: Notice) {
  const newNotice: Notice = {} as Notice;
  newNotice.id = notice.id;
  newNotice.createdAt = notice.createdAt;
  newNotice.order = notice.order;
  newNotice.noticeType = notice.noticeType;
  newNotice.title = notice.title;
  newNotice.text = notice.text;
  newNotice.containerId = notice.containerId;
  newNotice.eventId = notice.eventId;
  newNotice.placeId = notice.placeId;
  newNotice.restoId = notice.restoId;
  newNotice.stayId = notice.stayId;
  newNotice.transferId = notice.transferId;
  newNotice.tripId = notice.tripId;
  return newNotice;
}

export function setNoticeTypeText(noticeType: EnumNoticeType) {

  // noticeTypeText
  let noticeTypeText = "?";
  if (noticeType === 1) { noticeTypeText = "remember"; }
  if (noticeType === 2) { noticeTypeText = "documentation"; }
  if (noticeType === 3) { noticeTypeText = "booking"; }
  if (noticeType === 11) { noticeTypeText = "diary"; }
  if (noticeType === 99) { noticeTypeText = "other"; }
  return noticeTypeText;
}

export function orderNoticesByOrder(notices: Notice[]) {
  // console.log("utils-ref-info:orderInfos");
  notices.sort((a, b) => {
    if (a.order < b.order) { return -1; }
    if (a.order > b.order) { return 1; }
    return 0;
  });
}

// export function getNewNoticeId(notices: Array<Notice>) {
//   let id = 0;
//   for (const xNotice of notices) {
//     if (xNotice.id < id) { id = xNotice.id; }
//   }
//   id--;
//   return id;
// }

export function convertNoticeDates(notice: Notice) {
  notice.createdAt = new Date(notice.createdAt);
}


export function isNoticeInContainer(notice: Notice, container: Container) {

  // test all infos directly in container
  for (const xNotice of container.notices) {
    if (notice.id === xNotice.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of container.containers) {
    if (isNoticeInContainer(notice, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of container.places) {
    if (isNoticeInPlace(notice, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of container.events) {
    if (isNoticeInEvent(notice, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of container.stays) {
    if (isNoticeInStay(notice, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of container.restos) {
    if (isNoticeInResto(notice, xResto)) { return true; }
  }
  return false;
}

export function isNoticeInPlace(info: Notice, place: Place) {

  // test all infos directly in place
  for (const xNotice of place.notices) {
    if (info.id === xNotice.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of place.containers) {
    if (isNoticeInContainer(info, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of place.places) {
    if (isNoticeInPlace(info, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of place.events) {
    if (isNoticeInEvent(info, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of place.stays) {
    if (isNoticeInStay(info, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of place.restos) {
    if (isNoticeInResto(info, xResto)) { return true; }
  }
  return false;
}

export function isNoticeInEvent(info: Notice, event: Event) {

  // test all infos directly in event
  for (const xNotice of event.notices) {
    if (info.id === xNotice.id) {
      return true;
    }
  }
  return false;
}

export function isNoticeInStay(info: Notice, stay: Stay) {

  // test all infos directly in stay
  for (const xNotice of stay.notices) {
    if (info.id === xNotice.id) {
      return true;
    }
  }
  return false;
}

export function isNoticeInResto(info: Notice, resto: Resto) {

  // test all infos directly in resto
  for (const xNotice of resto.notices) {
    if (info.id === xNotice.id) {
      return true;
    }
  }
  return false;
}
export function isNoticeInTransfer(notice: Notice, transfer: Transfer) {

  // test all notices directly in transfer
  for (const xNotice of transfer.notices) {
    if (notice.id === xNotice.id) {
      return true;
    }
  }
  return false;
}

export function getMinLocalNoticeIdForTrip(inTrip: Trip) {
  let id = 0;
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const noticeId = getMinLocalNoticeIdInContainer(xContainer);
    if (noticeId < id) { id = noticeId; }
  }
  // search in trip-events
  for (const xEvent of inTrip.events) {
    const noticeId = getMinLocalNoticeIdInEvent(xEvent);
    if (noticeId < id) { id = noticeId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const noticeId = getMinLocalNoticeIdInPlace(xPlace);
    if (noticeId < id) { id = noticeId; }
  }
  // search in trip-restos
  for (const xResto of inTrip.restos) {
    const enoticeId = getMinLocalNoticeIdInResto(xResto);
    if (enoticeId < id) { id = enoticeId; }
  }
  // search in trip-stays
  for (const xStay of inTrip.stays) {
    const noticeId = getMinLocalNoticeIdInStay(xStay);
    if (noticeId < id) { id = noticeId; }
  }
  // search in trip-transfers
  for (const xTransfer of inTrip.transfers) {
    const noticeId = getMinLocalNoticeIdInTransfer(xTransfer);
    if (noticeId < id) { id = noticeId; }
  }
  return id;
}
export function getMinLocalNoticeIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // test notices of container
  for (const xNotice of inContainer.notices) {
    if (xNotice.id < id) { id = xNotice.id; }
  }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const enoticeId = getMinLocalNoticeIdInContainer(xContainer);
    if (enoticeId < id) { id = enoticeId; }
  }
  // search in container-events
  for (const xEvent of inContainer.events) {
    const noticeId = getMinLocalNoticeIdInEvent(xEvent);
    if (noticeId < id) { id = noticeId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const noticeId = getMinLocalNoticeIdInPlace(xPlace);
    if (noticeId < id) { id = noticeId; }
  }
  // search in container-restos
  for (const xResto of inContainer.restos) {
    const noticeId = getMinLocalNoticeIdInResto(xResto);
    if (noticeId < id) { id = noticeId; }
  }
  // search in container-stays
  for (const xStay of inContainer.stays) {
    const noticeId = getMinLocalNoticeIdInStay(xStay);
    if (noticeId < id) { id = noticeId; }
  }
  // search in trip-transfers
  for (const xTransfer of inContainer.transfers) {
    const noticeId = getMinLocalNoticeIdInTransfer(xTransfer);
    if (noticeId < id) { id = noticeId; }
  }
  return id;
}
export function getMinLocalNoticeIdInEvent(inEvent: Event) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test notices of event
  for (const xNotice of inEvent.notices) {
    if (xNotice.id < id) { id = xNotice.id; }
  }
  return id;
}
export function getMinLocalNoticeIdInPlace(inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test notices of place
  for (const xNotice of inPlace.notices) {
    if (xNotice.id < id) { id = xNotice.id; }
  }
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const noticeId = getMinLocalNoticeIdInContainer(xContainer);
    if (noticeId < id) { id = noticeId; }
  }
  // search in place-events
  for (const xEvent of inPlace.events) {
    const noticeId = getMinLocalNoticeIdInEvent(xEvent);
    if (noticeId < id) { id = noticeId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const noticeId = getMinLocalNoticeIdInPlace(xPlace);
    if (noticeId < id) { id = noticeId; }
  }
  // search in place-restos
  for (const xResto of inPlace.restos) {
    const noticeId = getMinLocalNoticeIdInResto(xResto);
    if (noticeId < id) { id = noticeId; }
  }
  // search in place-stays
  for (const xStay of inPlace.stays) {
    const noticeId = getMinLocalNoticeIdInStay(xStay);
    if (noticeId < id) { id = noticeId; }
  }
  return id;
}
export function getMinLocalNoticeIdInResto(inResto: Resto) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test notices of event
  for (const xNotice of inResto.notices) {
    if (xNotice.id < id) { id = xNotice.id; }
  }
  return id;
}
export function getMinLocalNoticeIdInStay(inStay: Stay) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test notices of event
  for (const xNotice of inStay.notices) {
    if (xNotice.id < id) { id = xNotice.id; }
  }
  return id;
}
export function getMinLocalNoticeIdInTransfer(inTransfer: Transfer) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test notices of transfer
  for (const xNotice of inTransfer.notices) {
    if (xNotice.id < id) { id = xNotice.id; }
  }
  return id;
}


export function isNoticePrivate(notice: Notice, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isNoticeInContainer(notice, xContainer)) { return true; }
  }
  return false;
}
