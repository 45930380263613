import { Component, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { RockItAuthenticationService } from "../rockit-authentication.service";

@Component({
    templateUrl: "./rockit-social-login-popup.component.html",
    styleUrls: ["./rockit-social-login-popup.component.scss"]
})
export class RockitSocialLoginPopupComponent {
    public isGoogleLoginButtonVisible: boolean = environment.production;
    public isFacebookLoginButtonVisible: boolean;
    public isDevelopmentLoginButtonVisible: boolean = !environment.production;

    // tslint:disable-next-line:max-line-length
    constructor(public dialogRef: MatDialogRef<RockitSocialLoginPopupComponent>, private authenticationService: RockItAuthenticationService, private zone: NgZone) {
    }

    public async loginGoogleClick(): Promise<void> {
        await this.authenticationService.loginWithGoogle();
        this.zone.run(() => {
            this.dialogRef.close();
        });
    }

    public async loginFacebookClick(): Promise<void> {
        // await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
        // this.zone.run(() => {
        //     this.dialogRef.close();
        // });
    }

    public async loginDevelopmentClick(): Promise<void> {
        await this.authenticationService.loginWithDevelopmentUser();
        this.zone.run(() => {
            this.dialogRef.close();
        });
    }

    public onCancel() {
        this.dialogRef.close();
    }
}
