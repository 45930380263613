
import {
  Info, RefInfo, EnumThemeType, Trip, Container, Place, Event, Stay, Resto, EnumInfoDataType,
  Transfer
} from "src/dto.generated/api";

export function createNewInfo() {
  const newInfo: Info = {} as Info;
  newInfo.infoType = EnumThemeType.Undef;
  newInfo.order = 1;
  newInfo.dataType = EnumInfoDataType.Text;
  return newInfo;
}

export function cloneInfo(info: Info) {
  const newInfo: Info = {} as Info;
  newInfo.id = info.id;
  newInfo.order = info.order;
  newInfo.title = info.title;
  newInfo.providerName = info.providerName;
  newInfo.text = info.text;
  newInfo.infoType = info.infoType;
  newInfo.dataType = info.dataType;
  newInfo.containerId = info.containerId;
  newInfo.eventId = info.eventId;
  newInfo.placeId = info.placeId;
  newInfo.restoId = info.restoId;
  newInfo.stayId = info.stayId;
  newInfo.transferId = info.transferId;
  newInfo.tripId = info.tripId;
  return newInfo;
}

export function createNewInfoFromRefInfo(refInfo: RefInfo) {
  const newInfo = createNewInfo();
  newInfo.refInfoId = refInfo.id;
  newInfo.title = refInfo.title;
  if (refInfo.providerName !== undefined) { newInfo.providerName = refInfo.providerName; }
  if (refInfo.text !== undefined) { newInfo.text = refInfo.text; }
  if (refInfo.dataType) { newInfo.dataType = refInfo.dataType; }
  return newInfo;
}

export function orderInfosByOrder(infos: Info[]) {
  // console.log("utils-info:orderInfos");
  infos.sort((a, b) => {
    if (a.order < b.order) { return -1; }
    if (a.order > b.order) { return 1; }
    return 0;
  });
}

// export function getNewInfoId(infos: Array<Info>) {
//   let id = 0;
//   for (const xInfo of infos) {
//     if (xInfo.id < id) { id = xInfo.id; }
//   }
//   id--;
//   return id;
// }

export function isInfoInContainer(info: Info, container: Container) {

  // test all infos directly in container
  for (const xInfo of container.infos) {
    if (info.id === xInfo.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of container.containers) {
    if (isInfoInContainer(info, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of container.places) {
    if (isInfoInPlace(info, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of container.events) {
    if (isInfoInEvent(info, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of container.stays) {
    if (isInfoInStay(info, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of container.restos) {
    if (isInfoInResto(info, xResto)) { return true; }
  }
  return false;
}

export function isInfoInPlace(info: Info, place: Place) {

  // test all infos directly in place
  for (const xInfo of place.infos) {
    if (info.id === xInfo.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of place.containers) {
    if (isInfoInContainer(info, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of place.places) {
    if (isInfoInPlace(info, xPlace)) { return true; }
  }
  // test all child-events of place
  for (const xEvent of place.events) {
    if (isInfoInEvent(info, xEvent)) { return true; }
  }
  // test all child-stays of place
  for (const xStay of place.stays) {
    if (isInfoInStay(info, xStay)) { return true; }
  }
  // test all child-restos of place
  for (const xResto of place.restos) {
    if (isInfoInResto(info, xResto)) { return true; }
  }
  return false;
}

export function isInfoInEvent(info: Info, event: Event) {

  // test all infos directly in event
  for (const xInfo of event.infos) {
    if (info.id === xInfo.id) {
      return true;
    }
  }
  return false;
}

export function isInfoInStay(info: Info, stay: Stay) {

  // test all infos directly in stay
  for (const xInfo of stay.infos) {
    if (info.id === xInfo.id) {
      return true;
    }
  }
  return false;
}

export function isInfoInResto(info: Info, resto: Resto) {

  // test all infos directly in resto
  for (const xInfo of resto.infos) {
    if (info.id === xInfo.id) {
      return true;
    }
  }
  return false;
}

export function isInfoInTransfer(info: Info, transfer: Transfer) {

  // test all infoss directly in transfer
  for (const xInfo of transfer.infos) {
    if (info.id === xInfo.id) {
      return true;
    }
  }
  return false;
}

export function getMinLocalInfoIdForTrip(inTrip: Trip) {
  let id = 0;
  // test infos of trip
  for (const xInfo of inTrip.infos) {
    if (xInfo.id < id) { id = xInfo.id; }
  }
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const infoId = getMinLocalInfoIdInContainer(xContainer);
    if (infoId < id) { id = infoId; }
  }
  // search in trip-events
  for (const xEvent of inTrip.events) {
    const infoId = getMinLocalInfoIdInEvent(xEvent);
    if (infoId < id) { id = infoId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const infoId = getMinLocalInfoIdInPlace(xPlace);
    if (infoId < id) { id = infoId; }
  }
  // search in trip-restos
  for (const xResto of inTrip.restos) {
    const einfoId = getMinLocalInfoIdInResto(xResto);
    if (einfoId < id) { id = einfoId; }
  }
  // search in trip-stays
  for (const xStay of inTrip.stays) {
    const infoId = getMinLocalInfoIdInStay(xStay);
    if (infoId < id) { id = infoId; }
  }
  // search in trip-transfers
  for (const xTransfer of inTrip.transfers) {
    const infoId = getMinLocalInfoIdInTransfer(xTransfer);
    if (infoId < id) { id = infoId; }
  }
  return id;
}
export function getMinLocalInfoIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // test infos of container
  for (const xInfo of inContainer.infos) {
    if (xInfo.id < id) { id = xInfo.id; }
  }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const einfoId = getMinLocalInfoIdInContainer(xContainer);
    if (einfoId < id) { id = einfoId; }
  }
  // search in container-events
  for (const xEvent of inContainer.events) {
    const infoId = getMinLocalInfoIdInEvent(xEvent);
    if (infoId < id) { id = infoId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const infoId = getMinLocalInfoIdInPlace(xPlace);
    if (infoId < id) { id = infoId; }
  }
  // search in container-restos
  for (const xResto of inContainer.restos) {
    const infoId = getMinLocalInfoIdInResto(xResto);
    if (infoId < id) { id = infoId; }
  }
  // search in container-stays
  for (const xStay of inContainer.stays) {
    const infoId = getMinLocalInfoIdInStay(xStay);
    if (infoId < id) { id = infoId; }
  }
  // search in trip-transfers
  for (const xTransfer of inContainer.transfers) {
    const infoId = getMinLocalInfoIdInTransfer(xTransfer);
    if (infoId < id) { id = infoId; }
  }
  return id;
}
export function getMinLocalInfoIdInEvent(inEvent: Event) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test infos of event
  for (const xInfo of inEvent.infos) {
    if (xInfo.id < id) { id = xInfo.id; }
  }
  return id;
}
export function getMinLocalInfoIdInPlace(inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test infos of place
  for (const xInfo of inPlace.infos) {
    if (xInfo.id < id) { id = xInfo.id; }
  }
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const infoId = getMinLocalInfoIdInContainer(xContainer);
    if (infoId < id) { id = infoId; }
  }
  // search in place-events
  for (const xEvent of inPlace.events) {
    const infoId = getMinLocalInfoIdInEvent(xEvent);
    if (infoId < id) { id = infoId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const infoId = getMinLocalInfoIdInPlace(xPlace);
    if (infoId < id) { id = infoId; }
  }
  // search in place-restos
  for (const xResto of inPlace.restos) {
    const infoId = getMinLocalInfoIdInResto(xResto);
    if (infoId < id) { id = infoId; }
  }
  // search in place-stays
  for (const xStay of inPlace.stays) {
    const infoId = getMinLocalInfoIdInStay(xStay);
    if (infoId < id) { id = infoId; }
  }
  return id;
}
export function getMinLocalInfoIdInResto(inResto: Resto) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test infos of event
  for (const xInfo of inResto.infos) {
    if (xInfo.id < id) { id = xInfo.id; }
  }
  return id;
}
export function getMinLocalInfoIdInStay(inStay: Stay) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test infos of event
  for (const xInfo of inStay.infos) {
    if (xInfo.id < id) { id = xInfo.id; }
  }
  return id;
}
export function getMinLocalInfoIdInTransfer(inTransfer: Transfer) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test infos of transfer
  for (const xInfo of inTransfer.infos) {
    if (xInfo.id < id) { id = xInfo.id; }
  }
  return id;
}




export function isInfoPrivate(info: Info, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isInfoInContainer(info, xContainer)) { return true; }
  }
  return false;
}
