import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";

import { RockItApiConfig } from "./shell/rockit-api-config";
import { environment } from "../environments/environment";

import { ClientSettingsService } from "./services/client-settings.service";
import { GlobalService } from "./services/global.service";
import { TripService } from "./services/trip.service";
import { RefItemService } from "./services/ref-item.service";
import { RoutePlanningService } from "./services/route-planning.service";

import { ShellModule } from "./shell/shell.module";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./global-components/page-not-found/page-not-found.component";
import { FooterModule } from "./module-footer/footer.module";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";

// import { FileUploadModule } from "ng2-file-upload";
// import { ORIGIN_URL } from "@nguniversal/aspnetcore-engine";
// import { FileUploadControl } from "./controls/file-uploader/file-upload.control";

const apiConfig = new RockItApiConfig(environment.apiEndpoint);

console.log("Environment is: ", apiConfig);

export function provideApiConfig() {
  return apiConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    // BackButtonDisableModule.forRoot({
    //   preserveScrollPosition: true
    // }),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,

    ShellModule,
    FooterModule,
  ],
  exports: [],
  providers: [
    {
      provide: RockItApiConfig,
      useFactory: provideApiConfig
    },
    ClientSettingsService,
    GlobalService,
    TripService,
    RefItemService,
    RoutePlanningService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
