import { Injectable } from "@angular/core";
import { TripOptions } from "../models/TripOptions copy";

@Injectable()
export class ClientSettingsService {
  private environment: "Develop" | "Release";

  private storageConnectionStringDevelop = "http://localhost:10000/devstoreaccount1/";
  private storageConnectionStringRelease = "https://rocktravelplanner.blob.core.windows.net/";

  private baseUrlDevelop = "http://localhost:4200/";
  // private baseUrlRelease = "https://travelplanner.azureedge.net/";
  private baseUrlRelease = "https://trip4you.net/";

  private userTripSettings: TripOptions;

  public constructor() {
    console.log("host:", window.location.host);
    this.environment = window.location.host === "localhost:4200" ? "Develop" : "Release";
    console.log("Using environment:", this.environment);

    // this.environment = "Release";
  }

  public get storageConnectionString(): string {
    return this.environment === "Release" ? this.storageConnectionStringRelease : this.storageConnectionStringDevelop;
  }

  public get baseUrl(): string {
    return this.environment === "Release" ? this.baseUrlRelease : this.baseUrlDevelop;
  }

  public get clientDateFormat(): string {

    // value for locale=id
    const formatDMY = "d/M/yyyy";

    return formatDMY;
  }

  public set tripSettings(settings: TripOptions) {
    this.userTripSettings = settings;
  }
  public get tripSettings() {
    return this.userTripSettings;
  }

}
