import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { RockItCommandSenderService } from "../shell/rockit-command-sender.service";
import { ClientSettingsService } from "./client-settings.service";

import {
  AddImageContentCommand, AddImageContentCommandResult, AddRefImageContentCommand, AddRefImageContentCommandResult,
  AddUsageLogCommand, AddUsageLogCommandResult, AddXDocContentCommand, AddXDocContentCommandResult, EnumActionType, EnumAppType,
  EnumGlobalStatusCode,
  TestIfUserCanBeDeletedCommand, TestIfUserCanBeDeletedCommandResult,
  RemoveUserCommand, RemoveUserCommandResult,
  GetExploreUsageListByFilterCommand, GetExploreUsageListByFilterCommandResult,
  GetUsageNrOfTotalCommand, GetUsageNrOfTotalCommandResult,
  GetUsageNrOfUserIPsCommand, GetUsageNrOfUserIPsCommandResult,
  GetUsageNrOfUsersCommand, GetUsageNrOfUsersCommandResult,
  GetUsageOfUserCommand, GetUsageOfUserCommandResult,
  GetMapUsageCountItemsCommand, GetMapUsageCountItemsCommandResult,
  GetMapUsagePlannedRoutesCommand, GetMapUsagePlannedRoutesCommandResult,
  GetMapUsageListByFilterCommand, GetMapUsageListByFilterCommandResult,
  GetMapUsageSessionIdListCommand, GetMapUsageSessionIdListCommandResult,
  GetMapUsageUserIdListCommand, GetMapUsageUserIdListCommandResult,
  GetMapUsageLanguageListCommand, GetMapUsageLanguageListCommandResult,
  GetMapUsageReferrerListCommand, GetMapUsageReferrerListCommandResult,
  RemoveImageContentCommand, RemoveImageContentCommandResult, RemoveRefImageContentCommand, RemoveRefImageContentCommandResult,
  RemoveXDocContentCommand, RemoveXDocContentCommandResult, ReplaceRefInfoTextCommand, ReplaceRefInfoTextCommandResult,
  ReplaceRefLinkUrlCommand, ReplaceRefLinkUrlCommandResult, UsageLog, User, UsageLogMaps
} from "../../dto.generated/api";



@Injectable()
export class GlobalService {

  public mediaWidth = 0;
  public mediaHeight = 0;
  private editMode = false;
  private orderAvailable = false;
  private loggedInUser: User;

  constructor(
    private commandSenderService: RockItCommandSenderService,
    private clientSettingsService: ClientSettingsService,
    private http: HttpClient) { }

  // mediaWidth & height
  public setMediaWidth(width: number) {
    this.mediaWidth = width;
  }
  public getMediaWidth() {
    return this.mediaWidth;
  }
  public setMediaHeight(height: number) {
    this.mediaHeight = height;
  }
  public getMediaHeight() {
    return this.mediaHeight;
  }

  // editMode
  public setEditMode(editMode: boolean) {
    this.editMode = editMode;
  }
  public getEditMode() {
    return this.editMode;
  }

  // orderFunctions
  public setOrderAvailable(orderFunctions: boolean) {
    this.orderAvailable = orderFunctions;
  }
  public getOrderAvailable() {
    return this.orderAvailable;
  }

  public setLoggedInUser(user: User) {
    this.loggedInUser = user;
  }
  public getLoggedInUser() {
    return this.loggedInUser;
  }

  public isLogOn() {
    return true;
  }

  public async sendImageToServer(base64Image: string) {
    console.log("sendImageToServer-base64Image:");
    const result = await this.commandSenderService.sendCommand<AddImageContentCommandResult>
      (AddImageContentCommand.create({ base64Image }));
    // console.log("sendImageToServer-result:", result);
    return result;
  }
  public async removeImageFromServer(blobString: string) {
    console.log("removeImageFromServer:");
    const result = await this.commandSenderService.sendCommand<RemoveImageContentCommandResult>
      (RemoveImageContentCommand.create({ blobString }));
    // console.log("removeImageFromServer-result:", result);
    return result;
  }
  public getImageBaseUrl() {
    return `${this.clientSettingsService.storageConnectionString}travelplannerimages/`;
  }

  public async sendRefImageToServer(base64Image: string) {
    console.log("sendRefImageToServer-base64Image:");
    const result = await this.commandSenderService.sendCommand<AddRefImageContentCommandResult>
      (AddRefImageContentCommand.create({ base64Image }));
    // console.log("sendRefImageToServer-result:", result);
    return result;
  }
  public async removeRefImageFromServer(blobString: string) {
    console.log("removeRefImageFromServer:");
    const result = await this.commandSenderService.sendCommand<RemoveRefImageContentCommandResult>
      (RemoveRefImageContentCommand.create({ blobString }));
    // console.log("removeRefImageFromServer-result:", result);
    return result;
  }
  public getRefImageBaseUrl() {
    return `${this.clientSettingsService.storageConnectionString}travelplannerrefimages/`;
  }


  public async sendXDocToServer(base64Doc: string, fileExtension: string) {
    console.log("sendXDocToServer-base64Doc:");
    const result = await this.commandSenderService.sendCommand<AddXDocContentCommandResult>
      (AddXDocContentCommand.create({ base64Doc, fileExtension }));
    console.log("sendXDocToServer-result:", result);
    return result;
  }
  public async removeXDocFromServer(blobString: string) {
    console.log("removeXDocFromServer:");
    const result = await this.commandSenderService.sendCommand<RemoveXDocContentCommandResult>
      (RemoveXDocContentCommand.create({ blobString }));
    // console.log("removeRefImageFromServer-result:", result);
    return result;
  }
  public getXDocBaseUrl() {
    return `${this.clientSettingsService.storageConnectionString}travelplannerdocs/`;
  }

  public async addUsageLog(usageLog: UsageLog) {
    const result = await this.commandSenderService.sendCommand<AddUsageLogCommandResult>
      (AddUsageLogCommand.create({ newUsageLog: usageLog }));
    return result.addedUsageLog;
  }

  public async removeUserFromDb(userId: number) {
    // console.log("removeUserFromDb:userId", userId);
    const result = await this.commandSenderService.sendCommand<RemoveUserCommandResult>
      (RemoveUserCommand.create({ userId }));
    return result;
  }

  public async testIfUserCanBeDeleted(userId: number) {
    // console.log("testIfUserCanBeDeleted:");
    const result = await this.commandSenderService.sendCommand<TestIfUserCanBeDeletedCommandResult>
      (TestIfUserCanBeDeletedCommand.create({ userId }));
    // console.log("testIfUserCanBeDeleted-result:", result);
    return result;
  }

  public async getExploreUsageListByFilter(usageLogFilter: UsageLog, fromDate: Date, toDate: Date) {
    // console.log("getUsageByFilter:");
    const result = await this.commandSenderService.sendCommand<GetExploreUsageListByFilterCommandResult>
      (GetExploreUsageListByFilterCommand.create({ usageLogFilter, fromDate, toDate }));
    // console.log("getUsageCountItems-result:", result);
    return result.usageList;
  }

  public async getUsageNrOfUserIPs(appType: EnumAppType, actionType: EnumActionType, fromDate: Date, toDate: Date) {
    // console.log("getUsageNrOfUserIPs-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetUsageNrOfUserIPsCommandResult>
      (GetUsageNrOfUserIPsCommand.create({ appType, actionType, fromDate, toDate }));
    // console.log("getUsageNrOfUserIPs-result:", result);
    return result.nrOfUserIPs;
  }

  public async getUsageNrOfUsers(appType: EnumAppType, actionType: EnumActionType, fromDate: Date, toDate: Date) {
    // console.log("getUsageNrOfUsers-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetUsageNrOfUsersCommandResult>
      (GetUsageNrOfUsersCommand.create({ appType, actionType, fromDate, toDate }));
    // console.log("getUsageNrOfUsers-result:", result);
    return result.nrOfUsers;
  }
  public async getUsageNrOfTotal(appType: EnumAppType, actionType: EnumActionType, fromDate: Date, toDate: Date) {
    // console.log("getUsageNrOfTotal-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetUsageNrOfTotalCommandResult>
      (GetUsageNrOfTotalCommand.create({ appType, actionType, fromDate, toDate }));
    // console.log("getUsageNrOfTotal-result:", result);
    return result.nrOfTotal;
  }
  public async getUsageOfUser(userId: number, appType: EnumAppType, actionType: EnumActionType, fromDate: Date, toDate: Date) {
    console.log("getUsageOfUser-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetUsageOfUserCommandResult>
      (GetUsageOfUserCommand.create({ userId, appType, actionType, fromDate, toDate }));
    console.log("getUsageOfUser-result:", result);
    return result.nrOfUses;
  }
  // tslint:disable-next-line:max-line-length
  public async getMapUsageCountItems(actionType: EnumActionType, userLanguage: string, notLanguage: string, referrer: string, fromDate: Date, toDate: Date) {
    // console.log("getMapUsageCountItems-actionType:", actionType);
    const result = await this.commandSenderService.sendCommand<GetMapUsageCountItemsCommandResult>
      (GetMapUsageCountItemsCommand.create({ actionType, userLanguage, notLanguage, referrer, fromDate, toDate }));
    console.log("getMapUsageCountItems-result:", result);
    return result.countItems;
  }
  public async getMapUsagePlannedRoutes(fromDate: Date, toDate: Date) {
    // console.log("getMapUsagePlannedRoutes:");
    const result = await this.commandSenderService.sendCommand<GetMapUsagePlannedRoutesCommandResult>
      (GetMapUsagePlannedRoutesCommand.create({ fromDate, toDate }));
    // console.log("getMapUsageCountItems-result:", result);
    return result.usageList;
  }
  public async getMapUsageListByFilter(usageLogFilter: UsageLogMaps, fromDate: Date, toDate: Date) {
    // console.log("getMapUsageByFilter:");
    const result = await this.commandSenderService.sendCommand<GetMapUsageListByFilterCommandResult>
      (GetMapUsageListByFilterCommand.create({ usageLogFilter, fromDate, toDate }));
    // console.log("getMapUsageCountItems-result:", result);
    return result.usageList;
  }
  public async getMapUsageSessionIdList(fromDate: Date, toDate: Date) {
    // console.log("getMapUsageSessionIdList:");
    const result = await this.commandSenderService.sendCommand<GetMapUsageSessionIdListCommandResult>
      (GetMapUsageSessionIdListCommand.create({ fromDate, toDate }));
    // console.log("getMapUsageSessionIdList-result:", result);
    return result.sessionIdList;
  }
  public async getMapUsageUserIdList(fromDate: Date, toDate: Date) {
    // console.log("getMapUsageUserList:");
    const result = await this.commandSenderService.sendCommand<GetMapUsageUserIdListCommandResult>
      (GetMapUsageUserIdListCommand.create({ fromDate, toDate }));
    // console.log("getMapUsageUserList-result:", result);
    return result.userIdList;
  }
  public async getMapUsageLanguageList(fromDate: Date, toDate: Date) {
    // console.log("getMapUsageLanguageList:");
    const result = await this.commandSenderService.sendCommand<GetMapUsageLanguageListCommandResult>
      (GetMapUsageLanguageListCommand.create({ fromDate, toDate }));
    // console.log("getMapUsageLanguageList-result:", result);
    return result.languageList;
  }
  public async getMapUsageReferrerList(fromDate: Date, toDate: Date) {
    // console.log("getMapUsageReferrerList:");
    const result = await this.commandSenderService.sendCommand<GetMapUsageReferrerListCommandResult>
      (GetMapUsageReferrerListCommand.create({ fromDate, toDate }));
    // console.log("getMapUsageReferrerList-result:", result);
    return result.referrerList;
  }

  public async replaceRefInfoText(fromString: string, toString: string) {
    console.log("replaceRefInfoText-fromString:", fromString);
    const result = await this.commandSenderService.sendCommand<ReplaceRefInfoTextCommandResult>
      (ReplaceRefInfoTextCommand.create({ fromString, toString }));
    if (result.statusCode !== EnumGlobalStatusCode.Success) {
      console.log("replaceRefInfoText-result:", result);
    }
    return result;
  }

  public async replaceRefLinkUrl(fromString: string, toString: string) {
    console.log("replaceRefLinkUrl-fromString:", fromString);
    const result = await this.commandSenderService.sendCommand<ReplaceRefLinkUrlCommandResult>
      (ReplaceRefLinkUrlCommand.create({ fromString, toString }));
    if (result.statusCode !== EnumGlobalStatusCode.Success) {
      console.log("replaceRefLinkUrl-result:", result);
    }
    return result;
  }

}
