import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { Command, CommandResult } from "src/api/api";
import { RockItApiConfig } from "./rockit-api-config";
import { RockItAuthenticationService } from "./rockit-authentication.service";

@Injectable({
    providedIn: "root"
})
export class RockItCommandSenderService {
    private testConnectionSubscription: Subscription;
    private testConnectionIntervalSource: Observable<number>;
    private isConnectionTestActive = false;
    developerAuthenticationUser: string;

    constructor(private rockItApiConfig: RockItApiConfig, authenticationService: RockItAuthenticationService, private zone: NgZone) {
        authenticationService.developerAuthenticationUser$.subscribe(t => this.developerAuthenticationUser = t);
    }

    private isApiAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isApiAvailable$: Observable<boolean> = this.isApiAvailable.asObservable();

    private dateReviver(key, value) {
        // tslint:disable-next-line:max-line-length
        const dateFormat = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
        if (typeof value === "string" && dateFormat.test(value)) {
            return new Date(value);
        }

        return value;
    }

    public async sendCommand<TApiCommandResult extends CommandResult>(command: Command): Promise<TApiCommandResult> {
        // const commandName = (<any>command).__CommandType;
        // delete (<any>command).__CommandType;
        const commandName = (command as any).__CommandType;
        delete (command as any).__CommandType;

        console.log("Sending command", commandName, command);

        try {
            const fetchResult = await fetch(`${this.rockItApiConfig.apiUri}/${commandName}`, {
                method: "post",
                mode: "cors", // no-cors, cors, *same-origin
                cache: "no-cache",
                credentials: "include", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    TravelPlannerDevLoginUser: this.developerAuthenticationUser
                },
                redirect: "follow", // manual, *follow, error
                referrer: "no-referrer", // no-referrer, *client
                body: JSON.stringify(command), // body data type must match "Content-Type" header
            });

            const jsonResultString = await fetchResult.text();
            // const apiResult = <TApiCommandResult>JSON.parse(jsonResultString, this.dateReviver);
            const apiResult = JSON.parse(jsonResultString, this.dateReviver) as TApiCommandResult;

            this.isApiAvailable.next(true);
            console.log("Sending command succeeded", commandName, { response: apiResult, command });

            if (this.testConnectionSubscription) {
                this.testConnectionSubscription.unsubscribe();
            }

            return apiResult;
        } catch (error) {
            if (this.isApiAvailable.value) {
                this.isApiAvailable.next(false);
                this.testConnectionIntervalSource = interval(this.rockItApiConfig.reconnectIntervalMs);
                this.testConnectionSubscription = this.testConnectionIntervalSource.subscribe(async () => {
                    if (this.isConnectionTestActive) {
                        return;
                    }
                    this.isConnectionTestActive = true;
                    try {
                        await this.sendCommand<CommandResult>({ __CommandType: "PingCommand" });
                    } finally {
                        this.isConnectionTestActive = false;
                    }
                });
            }

            console.warn("Sending command failed", commandName, { error, command });
            this.zone.run(async () => { });
            throw error;
        }
    }
}
