
import {
  Place, Event, Stay, Resto, Link, Info, Image, Duty, Notice, XDoc,
  EnumStayType, EnumSituationType, Trip, Container, Transfer
} from "src/dto.generated/api";
import { RefStay } from "src/dto.generated/api";
import { ItemObject } from "../models/item-object";
import { getContainerInTrip, getContainerParentContainerForTrip, getContainerParentPlaceForTrip } from "./utils-container";
import { isDutyInStay } from "./utils-duty";
import { isImageInStay } from "./utils-image";
import { isInfoInStay } from "./utils-info";
import { isLinkInStay } from "./utils-link";
import { isNoticeInStay } from "./utils-notice";
import { getPlaceInTrip, getPlaceParentContainerForTrip, getPlaceParentPlaceForTrip } from "./utils-place";
import { isXDocInStay } from "./utils-xdoc";


export function createNewStay() {
  const newStay: Stay = {} as Stay;
  newStay.order = 1;
  newStay.from = new Date(Date.UTC(0, 0, 1));
  newStay.to = new Date(Date.UTC(0, 0, 1));
  newStay.dateActive = 1;
  newStay.links = new Array<Link>();
  newStay.infos = new Array<Info>();
  newStay.images = new Array<Image>();
  newStay.duties = new Array<Duty>();
  newStay.notices = new Array<Notice>();
  newStay.xDocs = new Array<XDoc>();
  return newStay;
}

export function cloneStay(stay: Stay) {
  const newStay: Stay = {} as Stay;
  newStay.id = stay.id;
  newStay.order = stay.order;
  newStay.name = stay.name;
  newStay.description = stay.description;
  newStay.descUrl = stay.descUrl;
  newStay.stayType = stay.stayType;
  newStay.placeType = stay.placeType;
  newStay.address = stay.address;
  newStay.tel = stay.tel;
  newStay.email = stay.email;
  newStay.containerId = stay.containerId;
  newStay.placeId = stay.placeId;
  newStay.tripId = stay.tripId;
  return newStay;
}

export function createNewStayFromRefStay(refStay: RefStay) {
  const newStay = createNewStay();
  newStay.name = refStay.name;
  newStay.refStayId = refStay.id;
  if (refStay.stayType !== undefined) { newStay.stayType = refStay.stayType; }
  if (refStay.placeType !== undefined) { newStay.placeType = refStay.placeType; }
  if (refStay.priceCategory !== undefined) { newStay.priceCategory = refStay.priceCategory; }
  if (refStay.rating !== undefined) { newStay.rating = refStay.rating; }
  if (refStay.description !== undefined) { newStay.description = refStay.description; }
  if (refStay.descUrl !== undefined) { newStay.descUrl = refStay.descUrl; }
  if (refStay.tel !== undefined) { newStay.tel = refStay.tel; }
  if (refStay.address !== undefined) { newStay.address = refStay.address; }
  if (refStay.email !== undefined) { newStay.email = refStay.email; }
  if (refStay.coordLon !== undefined) { newStay.coordLon = refStay.coordLon; }
  if (refStay.coordLat !== undefined) { newStay.coordLat = refStay.coordLat; }
  if (refStay.zoom !== undefined) { newStay.zoom = refStay.zoom; }
  return newStay;
}

// export function getNewStayId(stays: Array<Stay>) {
//   let id = 0;
//   for (const xStay of stays) {
//     if (xStay.id < id) { id = xStay.id; }
//   }
//   id--;
//   return id;
// }

export function convertStayDates(stay: Stay) {
  stay.from = new Date(stay.from);
  stay.to = new Date(stay.to);
}


export function isItemInStay(item: ItemObject, stay: Stay) {
  if (item.itemType === "Duty") {
    if (isDutyInStay(item.item, stay)) { return true; }
  }
  if (item.itemType === "Info") {
    if (isInfoInStay(item.item, stay)) { return true; }
  }
  if (item.itemType === "Link") {
    if (isLinkInStay(item.item, stay)) { return true; }
  }
  if (item.itemType === "Image") {
    if (isImageInStay(item.item, stay)) { return true; }
  }
  if (item.itemType === "Notice") {
    if (isNoticeInStay(item.item, stay)) { return true; }
  }
  if (item.itemType === "XDoc") {
    if (isXDocInStay(item.item, stay)) { return true; }
  }
}

export function isStayInContainer(stay: Stay, inContainer: Container) {

  // test all stays directly in container
  for (const xStay of inContainer.stays) {
    if (stay.id === xStay.id) {
      return true;
    }
  }
  // test all child-containers of container
  for (const xContainer of inContainer.containers) {
    if (isStayInContainer(stay, xContainer)) { return true; }
  }
  // test all child-places of container
  for (const xPlace of inContainer.places) {
    if (isStayInPlace(stay, xPlace)) { return true; }
  }
  return false;
}

export function isStayInPlace(stay: Stay, inPlace: Place) {

  // test all stays directly in place
  for (const xStay of inPlace.stays) {
    if (stay.id === xStay.id) {
      return true;
    }
  }
  // test all child-containers of place
  for (const xContainer of inPlace.containers) {
    if (isStayInContainer(stay, xContainer)) { return true; }
  }
  // test all child-places of place
  for (const xPlace of inPlace.places) {
    if (isStayInPlace(stay, xPlace)) { return true; }
  }
  return false;
}

export function isStayPrivate(stay: Stay, inTrip: Trip) {
  for (const xContainer of inTrip.containers) {
    if (!xContainer.userId) { continue; }
    if (isStayInContainer(stay, xContainer)) { return true; }
  }
  return false;
}

export function getStayInTrip(stayId: number, inTrip: Trip) {
  // console.log("util-testos:getRestoInTrip-restoId", restoId);

  // search in stays
  // for (const stay of inTrip.stays) {
  //   if (stay.id === stayId) { return stay; }
  // }
  // search in containers
  for (const xContainer of inTrip.containers) {
    const stay = getStayInContainer(stayId, xContainer);
    if (stay) { return stay; }
  }
  // search in sub-places
  for (const xPlace of inTrip.places) {
    const stay = getStayInPlace(stayId, xPlace);
    if (stay) { return stay; }
  }
}
export function getStayInContainer(stayId: number, inContainer: Container) {
  // console.log("util-testos:getRestoInTrip-restoId", restoId);

  // search in stays
  for (const stay of inContainer.stays) {
    if (stay.id === stayId) { return stay; }
  }
  // search in containers
  for (const xContainer of inContainer.containers) {
    const stay = getStayInContainer(stayId, xContainer);
    if (stay) { return stay; }
  }
  // search in sub-places
  for (const xPlace of inContainer.places) {
    const stay = getStayInPlace(stayId, xPlace);
    if (stay) { return stay; }
  }
}
export function getStayInPlace(stayId: number, inPlace: Place) {
  // console.log("util-testos:getRestoInTrip-restoId", restoId);

  // search in stays
  for (const stay of inPlace.stays) {
    if (stay.id === stayId) { return stay; }
  }
  // search in containers
  for (const xContainer of inPlace.containers) {
    const stay = getStayInContainer(stayId, xContainer);
    if (stay) { return stay; }
  }
  // search in sub-places
  for (const xPlace of inPlace.places) {
    const stay = getStayInPlace(stayId, xPlace);
    if (stay) { return stay; }
  }
}

export function getMinLocalStayIdForTrip(inTrip: Trip) {
  let id = 0;
  // test stays of trip
  for (const xStay of inTrip.stays) {
    if (xStay.id < id) { id = xStay.id; }
  }
  // search in trip-containers
  for (const xContainer of inTrip.containers) {
    const stayId = getMinLocalStayIdInContainer(xContainer);
    if (stayId < id) { id = stayId; }
  }
  // search in trip-places
  for (const xPlace of inTrip.places) {
    const stayId = getMinLocalStayIdInPlace(xPlace);
    if (stayId < id) { id = stayId; }
  }
  return id;
}
export function getMinLocalStayIdInContainer(inContainer: Container) {
  // console.log("EventFind:getPlaceInContainer-placeId", placeId);
  let id = 0;
  // test stays of container
  for (const xStay of inContainer.stays) {
    if (xStay.id < id) { id = xStay.id; }
  }
  // search in container-containers
  for (const xContainer of inContainer.containers) {
    const stayId = getMinLocalStayIdInContainer(xContainer);
    if (stayId < id) { id = stayId; }
  }
  // search in container-places
  for (const xPlace of inContainer.places) {
    const stayId = getMinLocalStayIdInPlace(xPlace);
    if (stayId < id) { id = stayId; }
  }
  return id;
}
export function getMinLocalStayIdInPlace(inPlace: Place) {
  // console.log("EventFind:getPlaceInPlace-placeId", placeId);
  let id = 0;
  // test stays of place
  for (const xStay of inPlace.stays) {
    if (xStay.id < id) { id = xStay.id; }
  }
  // search in place-containers
  for (const xContainer of inPlace.containers) {
    const stayId = getMinLocalStayIdInContainer(xContainer);
    if (stayId < id) { id = stayId; }
  }
  // search in place-places
  for (const xPlace of inPlace.places) {
    const stayId = getMinLocalStayIdInPlace(xPlace);
    if (stayId < id) { id = stayId; }
  }
  return id;
}

export function getStayParentContainerForTrip(stay: Stay, trip: Trip, onlyForRefRegion: boolean) {
  // console.log("utilsStay:getStayParentContainerForTrip-event", event);

  // parent is trip
  if (stay.tripId) { return null; }

  // parent is container
  if (stay.containerId) {
    const parentContainer = getContainerInTrip(stay.containerId, trip);
    // console.log("UtilsStay:getEventParentContainerForTrip-parentContainer", parentContainer);
    if (!onlyForRefRegion) { return parentContainer; }
    if (parentContainer.refRegionId || parentContainer.refContainerId) { return parentContainer; }
    const resContainer = getContainerParentContainerForTrip(parentContainer, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }

  // parent is place
  if (stay.placeId) {
    const parentPlace = getPlaceInTrip(stay.placeId, trip);
    // console.log("UtilsStay:getEventParentContainerForTrip-parentPlace", parentPlace);
    const resContainer = getPlaceParentContainerForTrip(parentPlace, trip, onlyForRefRegion);
    if (resContainer) { return resContainer; }
  }

}

export function getStayParentPlaceForTrip(stay: Stay, trip: Trip, onlyForRefPlace: boolean) {
  // console.log("UtilsStay:getEventParentPlaceForTrip-event", event);

  // parent is container
  if (stay.containerId) {
    const parentContainer = getContainerInTrip(stay.containerId, trip);
    // console.log("UtilsStay:getStayParentPlaceForTrip-parentContainer", parentContainer);
    const resPlace = getContainerParentPlaceForTrip(parentContainer, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }

  // parent is place
  if (stay.placeId) {
    const parentPlace = getPlaceInTrip(stay.placeId, trip);
    // console.log("UtilsStay:getStayParentPlaceForTrip-parentPlace", parentPlace);
    if (!onlyForRefPlace) { return parentPlace; }
    if (parentPlace.refPlaceId) { return parentPlace; }
    const resPlace = getPlaceParentPlaceForTrip(parentPlace, trip, onlyForRefPlace);
    if (resPlace) { return resPlace; }
  }
}


