import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./global-components/page-not-found/page-not-found.component";

const routes: Routes = [
  { path: "", redirectTo: "/explore", pathMatch: "full" },
  // planner
  {
    path: "planner",
    loadChildren: () => import("./module-planner/trip-planner.module").then(mod => mod.TripsHomeModule),
  },
  {
    path: "trips",
    loadChildren: () => import("./module-trips/trips.module").then(mod => mod.TripsModule),
  },
  {
    path: "trips/share/:key",
    loadChildren: () => import("./module-trips/trips.module").then(mod => mod.TripsModule),
  },
  // examples
  {
    path: "examples",
    loadChildren: () => import("./module-examples/examples.module").then(mod => mod.ExamplesModule),
  },
  // explore
  {
    path: "explore",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "region/:regionname",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "regionId/:regionId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "place/:placename",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "placeId/:placeId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "event/:eventname",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "eventId/:eventId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "stay/:stayname",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "stayId/:stayId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "restoId/:restoId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "resto/:restoname",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  {
    path: "containerId/:containerId",
    loadChildren: () => import("./module-explore/explore.module").then(mod => mod.ExploreModule),
  },
  // admin
  {
    path: "admin",
    loadChildren: () => import("./module-admin/admin.module").then(mod => mod.AdminModule),
  },
  // footer pages
  {
    path: "info",
    loadChildren: () => import("./module-footer-pages/footer-pages.module").then(mod => mod.FooterPagesModule),
  },
  // test
  {
    path: "test",
    loadChildren: () => import("./module-test/test.module").then(mod => mod.TestModule),
  },
  // default
  {
    path: "**",
    component: PageNotFoundComponent, // 404 page
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


