import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { RockItApiConfig } from "./rockit-api-config";

@Injectable({
  providedIn: "root"
})
export class RockItAuthenticationService {
  private developerAuthenticationUser: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public developerAuthenticationUser$: Observable<string> = this.developerAuthenticationUser.asObservable();

  private userId: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public userId$: Observable<string> = this.userId.asObservable();

  private userName: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public userName$: Observable<string> = this.userName.asObservable();

  private identityProvider: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public identityProvider$: Observable<string> = this.identityProvider.asObservable();

  constructor(private rockItApiConfig: RockItApiConfig, private httpClient: HttpClient, private router: Router) {
    this.updateUserId();
  }

  async updateUserId(): Promise<void> {
    if (environment.loginWithDevUser) {
      // comment this, if no autologin developer
      this.loginWithDevelopmentUser();
    } else {
      const result = await this.httpClient.get<{ clientPrincipal: any }>(environment.apiEndpoint + "/.auth/me")
        .pipe(catchError(e => of({ clientPrincipal: null }))).toPromise();

      this.userId.next(result?.clientPrincipal?.userId);
      this.userName.next(result?.clientPrincipal?.userDetails);
      this.identityProvider.next(result?.clientPrincipal?.identityProvider);
      console.log("RockItAuthenticationService:updateUserId-result", result);
    }
  }

  public async loginWithGoogle(): Promise<void> {
    // window.location.href = environment.apiEndpoint + "/.auth/login/google";
    const currentRoute = this.router.routerState.snapshot.url;
    // console.log("RockItAuthenticationService:loginWithGoogle", { currentRoute });
    window.location.href = environment.apiEndpoint + `/.auth/login/google?post_login_redirect_uri=https://trip4you.net${currentRoute}`;
  }

  public async loginWithDevelopmentUser(): Promise<void> {
    this.userId.next("dev");
    this.userName.next("Developer");
    this.developerAuthenticationUser.next("develop");
  }

  public async signOut() {
    console.log("RockItAuthenticationService:signOut");
    if (this.userId.value === "dev") {
      this.logoutWithDevelopmentUser();
    } else {
      // window.location.href = environment.apiEndpoint + "/.auth/logout";
      const currentRoute = this.router.routerState.snapshot.url;
      // console.log("RockItAuthenticationService:signOut", { currentRoute });
      window.location.href = environment.apiEndpoint + `/.auth/logout?post_logout_redirect_uri=https://trip4you.net${currentRoute}`;
    }
  }

  public async logoutWithDevelopmentUser(): Promise<void> {
    this.userId.next(undefined);
    this.userName.next(undefined);
    this.developerAuthenticationUser.next(undefined);
  }

  // private async getAppServiceToken(providerLoginIfo: any, userName: string) {
  //     const fetchResult = await fetch(`${this.rockItApiConfig.backendUri}/.auth/login/google`, {
  //         method: "POST",
  //         mode: "cors", // no-cors, cors, *same-origin
  //         cache: "no-cache",
  //         credentials: "same-origin", // include, *same-origin, omit
  //         headers: {
  //             "Content-Type": "application/json"
  //         },
  //         redirect: "follow", // manual, *follow, error
  //         referrer: "no-referrer", // no-referrer, *client
  //         body: JSON.stringify(providerLoginIfo),
  //     });

  //     const authResult = await fetchResult.json();

  //     this.authenticationToken.next(authResult.authenticationToken);
  //     this.userId.next(authResult.user.userId);
  //     this.userName.next(authResult.user.userName);
  // }
}
