<h1 mat-dialog-title>Trip4You login</h1>

<div mat-dialog-content>
    <div style="display: flex; flex-direction: column; text-align: center; margin: auto;">
        <p *ngIf="isGoogleLoginButtonVisible">Log in with your Google account.</p>
        <button *ngIf="isGoogleLoginButtonVisible" mat-button class="login-button" style="background: #dd4b39"
            (click)="loginGoogleClick()">
            <div class="login-button-content">
                <div class="login-button-icon">
                    <svg viewBox="0 0 24 24">
                        <path fill="#FFFFFF"
                            d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" />
                    </svg>
                </div>
                <div class="login-button-text">
                    Google
                </div>
            </div>
        </button>
        <button *ngIf="isFacebookLoginButtonVisible" mat-button class="login-button" style="background: #3b5998"
            (click)="loginFacebookClick()">
            <div class="login-button-content">
                <div class="login-button-icon">
                    <svg viewBox="0 0 24 24">
                        <path fill="#FFFFFF"
                            d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z" />
                    </svg>
                </div>
                <div class="login-button-text">
                    Facebook
                </div>
            </div>
        </button>
        <p *ngIf="isDevelopmentLoginButtonVisible">Log in as developer.</p>
        <button *ngIf="isDevelopmentLoginButtonVisible" mat-button class="login-button" style="background: #160ba7"
            (click)="loginDevelopmentClick()">
            <div class="login-button-content">
                <div class="login-button-text">
                    dev login
                </div>
            </div>
        </button>
    </div>
</div>
<div mat-dialog-actions>
    <div style="display: flex; flex-direction: row-reverse; width:100%;">
        <button mat-button (click)="onCancel()">Cancel</button>
        <!-- <button mat-button cdkFocusInitial>Ok</button> -->
    </div>
</div>